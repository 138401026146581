import React, { useState, useMemo } from 'react';
import Select, { components } from 'react-select';

import {
  Box,
  Flex,
  Text,
  Spinner,
  SimpleGrid,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';
import { useGetBrandChallengesQuery } from '../../../Redux/features/challenges/challengesApiSlice';
import { useGetBrandDataOverviewQuery } from '../../../Redux/features/data/dataApiSlice';

import FullScreenLoader from '../../FullScreenLoader/FullScreenLoader';
import EmptyOverview from '../../EmptyOverview/EmptyOverview';

import formatNumber from '../../../Helpers/formatNumbers';
import formatNumbersStandard from '../../../Helpers/formatNumbersStandard';

export default function DashboardOverview() {
  // Selector de challenges para el overview
  const [selectedChallenges, setSelectedChallenges] = useState([]);
  const handleChange = selectedOptions => {
    const selectedIds = selectedOptions
      ? selectedOptions.map(option => option.value)
      : [];
    setSelectedChallenges(selectedIds);
  };

  // Plataforma
  const [selectedPlatform, setSelectedPlatform] = useState('tiktok');
  const handlePlatformSelect = platform => {
    setSelectedPlatform(platform);
  };

  // Datos de la marca en general
  const { data: brandData, isLoading: isLoadingBrandData } =
    useGetBrandDataQuery();

  // Todos los retos de la marca para el listado
  const { data: brandChallenges, isLoading } = useGetBrandChallengesQuery({
    isMission: false,
  });

  // Datos/Overview generales o por reto o retos seleccionados
  const { data: brandDataOverview, isLoadingBrandDataOverview } =
    useGetBrandDataOverviewQuery({
      challengeIds: selectedChallenges.length > 0 ? selectedChallenges : [],
    });

  // Mostramos los generales o los datos que nos de la api si hay seleccionados concretos
  const dataOverview = brandDataOverview;

  const filteredChallenges = useMemo(() => {
    if (!brandChallenges?.data) return [];
    return brandChallenges.data.filter(challenge => {
      if (selectedPlatform === 'all') return true;
      if (selectedPlatform === 'instagram') return challenge.isInstagram;
      return !challenge.isInstagram;
    });
  }, [brandChallenges, selectedPlatform]);

  // Mostramos spinner general mientras cargan los datos
  if (isLoadingBrandDataOverview || isLoading || isLoadingBrandData) {
    return <FullScreenLoader />;
  }

  // Si la marca no tiene challenges es que acaba de entrar o no tiene un plan aún, le mostramos un CTA
  if (brandChallenges?.data.length === 0) {
    return (
      <EmptyOverview
        type={
          brandData?.data?.availablePlans.length !== 0
            ? 'noChallengesButCredits'
            : ''
        }
      />
    );
  }

  const challengeSelectorOptions = filteredChallenges.map(c => ({
    label: c.title,
    value: c.id,
  }));

  // Custom styles for React Select to match the platform selector
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '32px',
      height: '32px',
      borderRadius: '6px',
      borderColor: '#e2e8f0',
      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : provided.boxShadow,
      '&:hover': {
        borderColor: '#3182ce',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      height: '32px',
      padding: '0 6px',
    }),
    input: provided => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '32px',
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: '#e2e8f0',
      borderRadius: '4px',
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: '#000',
      fontSize: '14px',
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: '#000',
      ':hover': {
        backgroundColor: '#cbd5e0',
        color: '#000',
      },
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  const CustomMultiValueLabel = props => {
    const { data } = props;
    // Truncate the label to 8 characters
    const truncatedLabel =
      data.label.length > 8 ? data.label.substring(0, 8) : data.label;

    return (
      <components.MultiValueLabel {...props}>
        {truncatedLabel}
      </components.MultiValueLabel>
    );
  };

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Métricas de tus retos
          </Text>
          <Flex>
            {/* Selector de plataforma */}
            <Flex
              mr={4}
              height="32px"
              borderRadius="6px"
              border="1px solid #e2e8f0"
            >
              <Flex
                borderLeftRadius="6px"
                cursor="pointer"
                px={5}
                justifyContent="center"
                alignItems="center"
                fontSize="14px"
                bg={selectedPlatform === 'tiktok' ? '#e2e8f0' : 'white'}
                borderRight={
                  selectedPlatform === 'all' ? '1px solid #e2e8f0' : ''
                }
                boxShadow={
                  selectedPlatform === 'tiktok' ? 'inset 0 0 10px #bbbbbb' : ''
                }
                onClick={() => handlePlatformSelect('tiktok')}
                lineHeight={1}
              >
                TikTok
              </Flex>
              <Flex
                cursor="pointer"
                px={5}
                justifyContent="center"
                alignItems="center"
                fontSize="14px"
                bg={selectedPlatform === 'instagram' ? '#e2e8f0' : 'white'}
                borderRight={
                  selectedPlatform === 'tiktok' ? '1px solid #e2e8f0' : ''
                }
                boxShadow={
                  selectedPlatform === 'instagram'
                    ? 'inset 0 0 10px #bbbbbb'
                    : ''
                }
                onClick={() => handlePlatformSelect('instagram')}
                lineHeight={1}
              >
                Instagram
              </Flex>
              <Flex
                borderRightRadius="6px"
                cursor="pointer"
                px={5}
                justifyContent="center"
                alignItems="center"
                fontSize="14px"
                bg={selectedPlatform === 'all' ? '#e2e8f0' : 'white'}
                boxShadow={
                  selectedPlatform === 'all' ? 'inset 0 0 10px #bbbbbb' : ''
                }
                onClick={() => handlePlatformSelect('all')}
                lineHeight={1}
              >
                Todos
              </Flex>
            </Flex>

            {/* Selector de retos */}
            <Select
              isMulti
              name="challenges"
              options={challengeSelectorOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              styles={customSelectStyles}
              placeholder="Selecciona retos"
              value={challengeSelectorOptions.filter(option =>
                selectedChallenges.includes(option.value)
              )}
              components={{ MultiValueLabel: CustomMultiValueLabel }}
            />
          </Flex>
        </Flex>
      </Box>

      <Box p={5}>
        <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Retos lanzados</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.numberOfChallenges)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Vídeos totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalVideos)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Impactos totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalViews)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Comentarios totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalComments)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>

      <Box pb={5} px={5}>
        <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel display="flex" alignItems="center">
                  Presupuesto invertido
                  <Popover placement="right">
                    <PopoverTrigger>
                      <InfoOutlineIcon
                        cursor="pointer"
                        marginTop="2px"
                        marginLeft="5px"
                        width="12px"
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader
                        fontFamily="DM Sans"
                        fontSize="12px"
                        fontWeight="bold"
                      >
                        Presupuesto invertido
                      </PopoverHeader>
                      <PopoverBody>
                        <Text fontFamily="DM Sans" fontSize="12px">
                          Se reflejará el importe final una vez hayan pasado 48
                          horas desde que se subieron los vídeos
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumbersStandard(dataOverview.totalReward / 100)
                  ) : (
                    <Spinner size="sm" />
                  )}{' '}
                  €
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>CPM</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(
                      dataOverview?.totalVariableReward /
                      100 /
                      (dataOverview?.totalViews / 1000)
                    )
                  ) : (
                    <Spinner size="sm" />
                  )}{' '}
                  €
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Nº de compartidos</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalShares)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Likes totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalLikes)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>

    </>
  );
}
