import React, { useState } from 'react';

import {
  Box,
  Flex,
  Text,
  Spinner,
  SimpleGrid,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  Select,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import { useGetBrandDataOverviewQuery } from '../../../Redux/features/data/dataApiSlice';

import {
  useGetBrandChallengeOverviewQuery,
  useGetBrandChallengesQuery,
} from '../../../Redux/features/challenges/challengesApiSlice';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';

import FullScreenLoader from '../../FullScreenLoader/FullScreenLoader';
import EmptyOverview from '../../EmptyOverview/EmptyOverview';

import formatNumber from '../../../Helpers/formatNumbers';

export default function DashboardMissionsOverview() {
  // Selector de challenges para el overview
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const handleChange = event => {
    setSelectedChallenge(event.target.value);
  };

  // Datos de la marca generales
  const { data: brandDataOverview, isLoadingBrandDataOverview } =
    useGetBrandDataOverviewQuery();

  // Retos de la marca para el listado
  const { data: brandChallenges, isLoading } = useGetBrandChallengesQuery({
    isMission: true
  });

  // Datos del challenge concreto seleccionado
  const { data: challengeDataOverview } = useGetBrandChallengeOverviewQuery(
    selectedChallenge,
    { skip: selectedChallenge === '' }
  );

  // Get brand data request
  const { data: brandData, isLoading: isLoadingBrandData } =
    useGetBrandDataQuery();

  const dataOverview = selectedChallenge
    ? challengeDataOverview
    : brandDataOverview;

  // Mostramos spinner general mientras cargan los datos
  if (isLoadingBrandDataOverview || isLoading || isLoadingBrandData) {
    return <FullScreenLoader />;
  }

  // Si la marca no tiene challenges es que acaba de entrar o no tiene un plan aún, le mostramos un CTA
  if (brandChallenges?.data.length === 0) {
    return (
      <EmptyOverview
        isMission
        type={
          brandData?.data?.availablePlans.length !== 0
            ? 'noChallengesButCredits'
            : ''
        }
      />
    );
  }

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Métricas de tus misiones
          </Text>
          {
            <Select
              size="sm"
              borderRadius="md"
              width="200px"
              placeholder="Todas las misiones"
              value={selectedChallenge}
              onChange={handleChange}
            >
              {brandChallenges &&
                brandChallenges.data.length > 0 &&
                brandChallenges?.data.map(challenge => {
                  return (
                    <option key={challenge.id} value={challenge.id}>
                      {challenge.title}
                    </option>
                  );
                })}
            </Select>
          }
        </Flex>
      </Box>

      <Box p={5}>
        <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Misiones lanzadas</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.numberOfChallenges)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Vídeos totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalVideos)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Impactos totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalViews)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Nº de creadores</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.numberOfUsersParticipating)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>

      <Box pb={5} px={5}>
        <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Comentarios totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalComments)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Vídeos aprobados</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalApprovedVideos)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Nº de compartidos</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalShares)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>Likes totales</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalLikes)
                  ) : (
                    <Spinner size="sm" />
                  )}
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>

      <Box px={5}>
        <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel display="flex" alignItems="center">
                  Presupuesto invertido
                  <Popover placement="right">
                    <PopoverTrigger>
                      <InfoOutlineIcon cursor="pointer" marginTop="2px" marginLeft="5px" width="12px" />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader
                        fontFamily="DM Sans"
                        fontSize="12px"
                        fontWeight="bold"
                      >
                        Presupuesto invertido
                      </PopoverHeader>
                      <PopoverBody>
                        <Text fontFamily="DM Sans" fontSize="12px">
                          Se reflejará el importe final una vez hayan pasado 48
                          horas desde que se subieron los vídeos
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(dataOverview.totalReward / 100)
                  ) : (
                    <Spinner size="sm" />
                  )}{' '}
                  €
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card variant="outline">
            <CardBody>
              <Stat>
                <StatLabel>CPM</StatLabel>
                <StatNumber>
                  {dataOverview ? (
                    formatNumber(
                      dataOverview?.totalVariableReward /
                        100 /
                        (dataOverview?.totalViews / 1000)
                    )
                  ) : (
                    <Spinner size="sm" />
                  )}{' '}
                  €
                </StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}
