/* eslint-disable no-unused-vars */

import { useState } from 'react';

import { Box, Flex, Button, Text } from '@chakra-ui/react';

import { useGetBrandChallengesQuery } from '../../../Redux/features/challenges/challengesApiSlice';

import BrandChallengeItem from '../../BrandChallengeItem/BrandChallengeItem';
import CustomLink from '../../CustomLink/CustomLink';
import EmptyChallenges from '../../EmptyChallenges/EmptyChallenges';

import ChallengeModal from '../../Onboarding/ChallengeModal/ChallengeModal';

import LogoLoader2 from '../../LogoLoader2/LogoLoader2';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';

function hasRevisionUserChallenges(challenge) {
  return challenge.metrics.pendingParticipationsCount > 0;
}

function NoInstagramChallenges() {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      minH="300px"
      borderRadius="6px"
      border="1px solid #e2e8f0"
      m="5"
    >
      <Box bg="white" p={10} textAlign="center">
        <Text fontSize="20px" fontWeight="bold">
          No tienes retos en Instagram
        </Text>
        <Text
          opacity={0.7}
          fontSize="14px"
          my={2}
          maxWidth="400px"
          lineHeight={1.1}
          textAlign="center"
        >
          Crea un nuevo reto de Instagram para comenzar a obtener contenido para
          tu marca
        </Text>
        <Flex justifyContent="center" mt={5} alignItems="center">
          <CustomLink to="/dashboard/challenges/new">
            <Button
              color="white"
              bg="black"
              _hover={{ bg: 'black', color: 'white' }}
            >
              Crear reto de Instagram
            </Button>
          </CustomLink>
        </Flex>
      </Box>
    </Flex>
  );
}

export default function DashboardChallengesAll() {
  const { data: brandChallenges, isLoading } = useGetBrandChallengesQuery({
    isMission: false,
  });

  // Get brand data request
  const { data: brandData, isLoading: isLoadingBrandData } =
    useGetBrandDataQuery();

  // Plataforma elegida para ver los retos
  const [selectedPlatform, setSelectedPlatform] = useState('tiktok');
  const handlePlatformSelect = platform => {
    setSelectedPlatform(platform);
  };

  if (isLoading || isLoadingBrandData) {
    return <LogoLoader2 />;
  }

  // Si la marca no tiene challenges es que acaba de entrar o no tiene un plan aún, le mostramos un CTA
  if (brandChallenges?.data.length === 0) {
    return (
      <>
        <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold" fontSize="24px">
              Retos
            </Text>
            <CustomLink to="/dashboard/challenges/new">
              <Button
                bg="black"
                color="white"
                _hover={{ bg: '#000', opacity: 0.75 }}
                size="sm"
              >
                Nuevo reto
              </Button>
            </CustomLink>
          </Flex>
        </Box>
        <EmptyChallenges
          type={
            brandData?.data?.availablePlans.length !== 0
              ? 'noChallengesButCredits'
              : ''
          }
        />
        <ChallengeModal />
      </>
    );
  }

  const filterChallenges = challenges => {
    return challenges.filter(challenge => {
      if (selectedPlatform === 'tiktok') {
        return !challenge.isInstagram;
      } else if (selectedPlatform === 'instagram') {
        return challenge.isInstagram;
      } else {
        return true;
      }
    });
  };

  const today = new Date();

  const activeChallenges = filterChallenges(
    brandChallenges?.data
      .filter(
        challenge => challenge.isActive || hasRevisionUserChallenges(challenge)
      )
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime()
      )
  );

  const comingSoonChallenges = filterChallenges(
    brandChallenges?.data
      .filter(
        challenge =>
          challenge.isActive === false && new Date(challenge.launchDate) > today
      )
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime()
      )
  );

  const finishedChallenges = filterChallenges(
    brandChallenges?.data
      .filter(
        challenge =>
          !challenge.isActive &&
          !hasRevisionUserChallenges(challenge) &&
          new Date(challenge.launchDate) < today
      )
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime()
      )
  );

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Text mr={4} fontWeight="bold" fontSize="24px">
              Mis retos
            </Text>
            <Flex height="32px" borderRadius="6px" border="1px solid #e2e8f0">
              <Flex
                borderLeftRadius="6px"
                cursor="pointer"
                px={5}
                justifyContent="center"
                alignItems="center"
                fontSize="14px"
                bg={selectedPlatform === 'tiktok' ? '#e2e8f0' : 'white'}
                boxShadow={
                  selectedPlatform === 'tiktok' ? 'inset 0 0 10px #bbbbbb' : ''
                }
                onClick={() => handlePlatformSelect('tiktok')}
                lineHeight={1}
              >
                TikTok
              </Flex>
              <Flex
                borderRightRadius="6px"
                cursor="pointer"
                px={5}
                justifyContent="center"
                alignItems="center"
                fontSize="14px"
                bg={selectedPlatform === 'instagram' ? '#e2e8f0' : 'white'}
                boxShadow={
                  selectedPlatform === 'instagram'
                    ? 'inset 0 0 10px #bbbbbb'
                    : ''
                }
                onClick={() => handlePlatformSelect('instagram')}
                lineHeight={1}
              >
                Instagram
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            <CustomLink to="/dashboard/challenges/new">
              <Button
                bg="black"
                color="white"
                _hover={{ bg: '#000', opacity: 0.75 }}
                size="sm"
              >
                Crear reto
              </Button>
            </CustomLink>
          </Flex>
        </Flex>
      </Box>

      {comingSoonChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Próximos retos (
              {comingSoonChallenges && comingSoonChallenges.length})
            </Text>
          </Box>
          {comingSoonChallenges &&
            comingSoonChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  challengeData={challenge}
                  brandData={brandData}
                  isComingSoon
                />
              );
            })}
        </Box>
      )}

      {/* Mostramos las cards de los challenges */}
      {activeChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Retos activos ({activeChallenges && activeChallenges.length})
            </Text>
          </Box>
          {activeChallenges &&
            activeChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  challengeData={challenge}
                  brandData={brandData}
                />
              );
            })}

          {/* si no hay retos activos.. */}
          {activeChallenges && activeChallenges.length === 0 && (
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              minH="300px"
              borderRadius="6px"
              border="1px solid #e2e8f0"
            >
              <Box bg="white" p={10} textAlign="center">
                <Text fontSize="20px" fontWeight="bold">
                  No tienes ningún reto activo
                </Text>
                <Text
                  opacity={0.7}
                  fontSize="14px"
                  my={2}
                  maxWidth="500px"
                  lineHeight={1.1}
                  textAlign="center"
                >
                  Lanza un nuevo reto y comienza a obtener vídeos para tu marca
                </Text>
                <Flex justifyContent="center" mt={5} alignItems="center">
                  <CustomLink to="/dashboard/challenges/new">
                    <Button
                      color="white"
                      bg="black"
                      _hover={{ bg: 'black', color: 'white' }}
                    >
                      Crear nuevo reto
                    </Button>
                  </CustomLink>
                </Flex>
              </Box>
            </Flex>
          )}
        </Box>
      )}

      {/* Mostramos las cards de los challenges FINALIZADOS si hay */}
      {finishedChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Retos finalizados (
              {finishedChallenges && finishedChallenges.length})
            </Text>
          </Box>
          {finishedChallenges &&
            finishedChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  challengeData={challenge}
                  brandData={brandData}
                />
              );
            })}
        </Box>
      )}

      {activeChallenges.length === 0 &&
        comingSoonChallenges.length === 0 &&
        finishedChallenges.length === 0 && <NoInstagramChallenges />}

      <ChallengeModal />
      {/* <ChallengeModalExplanation /> */}
    </>
  );
}
