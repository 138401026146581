/* eslint-disable no-unused-vars */

import React from 'react';

import { Box, Flex, Button, Text } from '@chakra-ui/react';

import {
  useGetBrandChallengesQuery,
} from '../../../Redux/features/challenges/challengesApiSlice';

import BrandChallengeItem from '../../BrandChallengeItem/BrandChallengeItem';
import CustomLink from '../../CustomLink/CustomLink';
import EmptyChallenges from '../../EmptyChallenges/EmptyChallenges';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';

export default function DashboardMissionsAll() {
  const { data: brandChallenges, isLoading } =
    useGetBrandChallengesQuery({
      isMission: true,
    });

  // Get brand data request
  const { data: brandData, isLoading: isLoadingBrandData } =
    useGetBrandDataQuery();

  if (isLoading || isLoadingBrandData) {
    return <p>Cargando información de las misiones..</p>;
  }

  // Si la marca no tiene challenges es que acaba de entrar o no tiene un plan aún, le mostramos un CTA
  if (brandChallenges?.data.length === 0) {
    return (
      <>
        <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold" fontSize="24px">
              Mis misiones
            </Text>
            <CustomLink to="/dashboard/missions/new">
              <Button
                bg="black"
                color="white"
                _hover={{ bg: '#000', opacity: 0.75 }}
                size="sm"
              >
                Nueva misión
              </Button>
            </CustomLink>
          </Flex>
        </Box>
        <EmptyChallenges
          isMission={true}
          type={
            brandData?.data?.availablePlans.length !== 0
              ? 'noChallengesButCredits'
              : ''
          }
        />
      </>
    );
  }

  // Misiones activas (isActive es true)
  const activeChallenges =
    brandChallenges &&
    brandChallenges?.data.length > 0 &&
    brandChallenges?.data
      .filter(challenge => challenge.isActive)
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime(),
      );

  const today = new Date();

  const comingSoonChallenges =
    brandChallenges &&
    brandChallenges?.data.length > 0 &&
    brandChallenges?.data
      .filter(
        challenge =>
          challenge.isActive === false && new Date(challenge.launchDate) > today,
      )
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime(),
      );

  // Misiones finalizadas (isActive es false)
  const finishedChallenges =
    brandChallenges &&
    brandChallenges?.data.length > 0 &&
    brandChallenges?.data
      .filter(
        challenge =>
          !challenge.isActive && new Date(challenge.launchDate) < today,
      )
      .sort(
        (a, b) =>
          new Date(b.launchDate).getTime() - new Date(a.launchDate).getTime(),
      );

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Mis misiones
          </Text>
          <CustomLink to="/dashboard/missions/new">
            <Button
              bg="black"
              color="white"
              _hover={{ bg: '#000', opacity: 0.75 }}
              size="sm"
            >
              Crear misión
            </Button>
          </CustomLink>
        </Flex>
      </Box>

      {comingSoonChallenges && comingSoonChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Próximas misiones
            </Text>
          </Box>
          {comingSoonChallenges &&
            comingSoonChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  isMission
                  challengeData={challenge}
                  brandData={brandData}
                  isComingSoon
                />
              );
            })}
        </Box>
      )}

      {/* Mostramos las cards de los challenges */}
      {activeChallenges && activeChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Misiones activas
            </Text>
          </Box>
          {activeChallenges &&
            activeChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  isMission
                  challengeData={challenge}
                  brandData={brandData}
                />
              );
            })}

          {/* si no hay retos activos.. */}
          {activeChallenges && activeChallenges.length === 0 && (
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              minH="300px"
              borderRadius="6px"
              border="1px solid #e2e8f0"
            >
              <Box bg="white" p={10} textAlign="center">
                <Text fontSize="20px" fontWeight="bold">
                  No tienes ninguna misión activa
                </Text>
                <Text
                  opacity={0.7}
                  fontSize="14px"
                  my={2}
                  maxWidth="500px"
                  lineHeight={1.1}
                  textAlign="center"
                >
                  Crea una misión y comienza a obtener vídeos para tu marca
                </Text>
                <Flex justifyContent="center" mt={5} alignItems="center">
                  <CustomLink to="/dashboard/missions/new">
                    <Button
                      color="white"
                      bg="black"
                      _hover={{ bg: 'black', color: 'white' }}
                    >
                      Crear misión
                    </Button>
                  </CustomLink>
                </Flex>
              </Box>
            </Flex>
          )}
        </Box>
      )}

      {/* Mostramos las cards de los challenges FINALIZADOS si hay */}
      {finishedChallenges && finishedChallenges.length > 0 && (
        <Box p="5">
          <Box pb={5}>
            <Text fontSize="20px" fontWeight="bold">
              Misiones finalizadas
            </Text>
          </Box>
          {finishedChallenges &&
            finishedChallenges.map(challenge => {
              return (
                <BrandChallengeItem
                  key={challenge.id}
                  isMission
                  challengeData={challenge}
                  brandData={brandData}
                />
              );
            })}
        </Box>
      )}
    </>
  );
}
