import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Box,
  useDisclosure,
  Flex,
  Card,
  Textarea,
  CardBody,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PopoverCloseButton,
  IconButton,
  useToast,
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  StepIcon,
  Heading,
} from '@chakra-ui/react';

import {
  InfoOutlineIcon,
  ArrowForwardIcon,
  ArrowBackIcon,
} from '@chakra-ui/icons';

import { FiMinusCircle } from 'react-icons/fi';

import CreatableSelect from 'react-select/creatable';

import ChallengePreviewerV2 from '../../ChallengePreviewerV2/ChallengePreviewerV2';
import TextEditor from '../../TextEditor/TextEditor';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';
import {
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
} from '../../../Redux/features/challenges/challengesApiSlice';

import ChallengeCreationImage from '../../../Assets/Images/nuevo-reto-banner.png';

import validateForm from '../../../Helpers/validateNewChallengeForm';
import { formatDate } from '../../../Utils/date';
import PlatformSelection from '../../PlatformSelection/PlatformSelection';

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const steps = [
  { title: 'Plataforma' },
  { title: 'Presupuesto' },
  { title: 'Detalles del reto' },
  { title: 'Información de pago' },
];

function calculateViews(totalCreators, views) {
  return Math.floor(totalCreators * views);
}

function calculateCreators(budget, fixed, variable, paidViews) {
  return Math.floor((budget * 0.6) / (fixed + variable * (paidViews / 1000)));
}

// Function to format numbers
function formatNumber(number) {
  if (number >= 1000 && number < 1000000) {
    return `${Math.round(number / 1000)} mil`; // Thousands
  } else if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`; // Millions
  } else {
    return number.toString(); // Handle numbers less than 1000
  }
}

export default function DashboardChallengesNew() {
  // Steps
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  // Router
  const navigate = useNavigate();

  // Get brand data request
  const { data: brandData } = useGetBrandDataQuery();

  // Redux
  const [createChallenge, { isLoading: isLoadingCreateChallenge }] =
    useCreateChallengeMutation();
  const [updateChallenge, { isLoading: isLoadingUpdateChallenge }] =
    useUpdateChallengeMutation();

  // Plataforma
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const handlePlatformSelect = platform => {
    setSelectedPlatform(platform);
  };

  // Basic text form data
  const [formData, setFormData] = useState({
    name: '',
    launchDate: '',
    finalizationDate: '',
    budget: 3000,
  });
  const handleChange = event => {
    const { name, value } = event.target;
    if (name !== 'launchDate' || isNaN(new Date(value))) {
      setFormData(prevData => ({ ...prevData, [name]: value }));
      return;
    }

    let finalizationDate = formData.finalizationDate;
    const defaultFinalizationDate = new Date(value);
    defaultFinalizationDate.setDate(defaultFinalizationDate.getDate() + 30);
    finalizationDate = formatDate(defaultFinalizationDate);
    setFormData(prevData => ({ ...prevData, [name]: value, finalizationDate }));
  };

  // Description
  const [descriptionHTML, setDescriptionHTML] = useState('');

  // Fixed reward
  const [fixedReward, setFixedReward] = useState(
    selectedPlatform === 'instagram' ? 8 : 5
  );
  const handleChangeFixedReward = (valueAsString, valueAsNumber) => {
    setFixedReward(valueAsNumber);
  };

  // Formateo para el input
  const formatToShowEur = val => val + ` €`;

  // Variable reward
  const [variableReward, setVariableReward] = useState(1);
  const handleChangeVariableReward = (valueAsString, valueAsNumber) => {
    setVariableReward(valueAsNumber);
  };

  // Banner marca
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerToSend, setSelectedBannerToSend] = useState(null);
  const handleImageBanner = e => {
    const file = e.target.files[0];
    setSelectedBannerToSend(file);
    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedBanner(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Links
  const [links, setLinks] = useState([{ text: '', url: '' }]);
  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };
  const addLink = () => {
    setLinks([...links, { text: '', url: '' }]);
  };
  const removeLink = index => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  // Hashtags
  const [inputHashtagValue, setInputHashtagValue] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const handleKeyDownHashtags = event => {
    if (!inputHashtagValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setHashtags(prev => [...prev, createOption(inputHashtagValue)]);
        setInputHashtagValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Mentions
  const [inputMentionValue, setInputMentionValue] = useState('');
  const [mentions, setMentions] = useState([]);
  const handleKeyDownMentions = event => {
    if (!inputMentionValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setMentions(prev => [...prev, createOption(inputMentionValue)]);
        setInputMentionValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Requirements
  const [inputRequirementValue, setInputRequirementValue] = useState('');
  const [requirements, setRequirements] = useState([]);
  const handleKeyDownRequirements = event => {
    if (!inputRequirementValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setRequirements(prev => [...prev, createOption(inputRequirementValue)]);
        setInputRequirementValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Video uploaded
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoToSend, setSelectedVideoToSend] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleVideoUpload = e => {
    const file = e.target.files[0];
    setSelectedVideoToSend(file);
    console.log(file);
    if (file) {
      setSelectedVideo(URL.createObjectURL(file));
    }
  };

  // Emails
  const [inputEmailValue, setInputEmailValue] = useState();
  const [notificationEmails, setNotificationEmails] = useState([]);
  const handleKeyDownNotificationEmails = event => {
    if (!inputEmailValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setNotificationEmails(prev => [...prev, createOption(inputEmailValue)]);
        setInputEmailValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Challenge goal modal
  const {
    isOpen: isOpenGoalModal,
    onOpen: onOpenGoalModal,
    onClose: onCloseGoalModal,
  } = useDisclosure();

  // Challenge Goal Text
  const [challengeGoal, setChallengeGoal] = useState('');
  const handleChangeChallengeGoal = e => {
    const inputValue = e.target.value;
    setChallengeGoal(inputValue);
  };

  // Store the challenge ID created for the update modal
  const [challengeId, setChallengeId] = useState('');

  useEffect(() => {
    if (brandData && brandData.data.email) {
      setNotificationEmails([createOption(brandData.data.email)]);
    }
  }, [brandData]);

  useEffect(() => {
    if (selectedPlatform === 'instagram') {
      setFixedReward(8);
    }
  }, [selectedPlatform]);

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Enviamos los datos del nuevo challenge
  const handleSubmit = async event => {
    const isValid = validateForm(
      showToast,
      formData,
      descriptionHTML,
      selectedVideo,
      hashtags,
      mentions,
      fixedReward,
      variableReward
    );

    if (notificationEmails.length === 0) {
      showToast('Debes poner al menos 1 email para los avisos', 'error', 2000);
      return;
    }

    if (isValid) {
      const newChallengeData = {
        title: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
        launchDate: formData.launchDate,
        finalizationDate: formData.finalizationDate,
        description: descriptionHTML,
        budget: Number(formData.budget) * 100,
        reward: {
          fixed: fixedReward * 100,
          variable: variableReward * 100,
        },
        hashtags: hashtags
          .map(item => {
            if (typeof item.value === 'string') {
              item.value = item.value.trim();
              if (item.value.startsWith('#')) {
                item.value = item.value.slice(1);
              }
            }
            return item.value;
          })
          .concat('CRUWI'),
        mentions: mentions.map(item => {
          if (typeof item.value === 'string') {
            item.value = item.value.trim();
            if (item.value.startsWith('@')) {
              item.value = item.value.slice(1);
            }
          }
          return item.value;
        }),
        requirements: requirements.map(item => {
          if (typeof item.value === 'string') {
            item.value = item.value.trim();
            item.value =
              item.value.charAt(0).toUpperCase() + item.value.slice(1);
          }
          return item.value;
        }),
        necessaryLinks: links,
        notificationEmails: notificationEmails.map(item => item.value),
        isInstagram: selectedPlatform === 'instagram',
      };

      const challengeFormData = new FormData();
      challengeFormData.append('data', JSON.stringify(newChallengeData));
      challengeFormData.append('banner', selectedBannerToSend);
      challengeFormData.append('video', selectedVideoToSend);

      try {
        const brandChallengeCreationResult = await createChallenge(
          challengeFormData
        ).unwrap();
        setActiveStep(4);
        setChallengeId(brandChallengeCreationResult.data._id);
        showToast('Reto creado con éxito', 'success', 3000);
        onOpenGoalModal();
      } catch (error) {
        console.log(error);
        showToast('Hubo un problema creando el reto', 'error');
      }
    }
  };

  // NUEVO: Enviamos el objetivo del reto
  const handleSubmitChallengeGoal = async () => {
    try {
      const data = {
        challengeId,
        updateData: {
          goal: challengeGoal,
        },
      };

      const updateChallengeResult = await updateChallenge(data).unwrap();
      console.log(updateChallengeResult);
      showToast(
        'La información de tu objetivo fue enviada con éxito',
        'success',
        3000
      );
      navigate('/dashboard/challenges');
    } catch (error) {
      console.log(error);
      showToast('Hubo un problema enviando el objetivo del reto', 'error');
    }
  };

  const handleCloseChallengeGoalModal = () => {
    onCloseGoalModal();
    navigate('/dashboard/challenges');
  };

  // Constantes
  const views = 3000;
  const paidViews = 2500;

  // Cálculos para el preview con el budget (Calculadora)
  const estimatedCreators = calculateCreators(
    formData.budget,
    fixedReward,
    variableReward,
    paidViews
  );
  const estimatedViews = calculateViews(estimatedCreators, views);

  // Format views and numberOfCreators
  const formattedViews = formatNumber(estimatedViews);
  const roundedNumberOfCreators = Math.floor(estimatedCreators);

  // Validate 1 step and go to next
  const goToBudget = () => {
    if (selectedPlatform === null) {
      showToast('Debes seleccionar una plataforma', 'error');
      return;
    }
    setActiveStep(1);
  };

  // Validate 1 step and go to next
  const goToChallengeDetails = () => {
    if (formData.budget < 500) {
      showToast('El presupuesto mínimo es de 500€', 'error');
      return;
    }
    setActiveStep(2);
  };

  // Validate 1 step and go to next
  const goToPayment = () => {
    const isValid = validateForm(
      showToast,
      formData,
      descriptionHTML,
      selectedVideo,
      hashtags,
      mentions,
      fixedReward,
      variableReward
    );

    if (isValid) {
      setActiveStep(3);
    }
  };

  // Mostramos un banner fijo a Penguin u marcas con retos del plan antiguo
  const hasOldPricing = Boolean(brandData.data.availablePlans.length > 0);

  return (
    <>
      {/* Cabecera página */}
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Lanza un nuevo reto
          </Text>
        </Flex>
      </Box>

      {/* Stepper */}
      <Box bg="#e2e8f0" borderBottom="1px solid #e2e8f0" p={5}>
        <Box maxW="900px">
          <Stepper
            color="#1a202c"
            size="md"
            orientation="horizontal"
            index={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator className="stepper_custom_indicator">
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                </Box>
                <StepSeparator className="stepper_custom_separator" />
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>

      {/* Pasos de la creación del reto */}
      {activeStep === 0 && (
        <Box p={5}>
          <Flex maxW="900px">
            {/* Calculadora */}
            <Box width="60%" mr="80px">
              <Text fontSize="20px" fontWeight="bold" mb={2}>
                ¿En qué plataforma quieres lanzar?
              </Text>
              <Text mb={5}>
                Selecciona dónde quieres que los creadores suban los vídeos del
                reto
              </Text>

              {/* Aquí las dos opciones */}
              <PlatformSelection
                selectedPlatform={selectedPlatform}
                handlePlatformSelect={handlePlatformSelect}
              />

              {/* Botón siguiente */}
              <Box mt="50px" textAlign="right">
                <Button
                  bg="black"
                  color="white"
                  _hover={{ bg: '#000', opacity: 0.75 }}
                  size="lg"
                  onClick={() => goToBudget()}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Ir al presupuesto
                </Button>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}

      {activeStep === 1 && (
        <Box p={5}>
          {hasOldPricing && (
            <Card maxW="400px" mb={5} border="1px solid">
              <CardBody>
                <Text color="black">
                  Estás creando un reto del tipo: <strong>Hyper-Growth</strong>
                </Text>
              </CardBody>
            </Card>
          )}

          <Flex maxW="900px">
            {/* Calculadora */}
            <Box width="60%" mr="80px">
              <Text fontSize="20px" fontWeight="bold" mb={2}>
                Presupuesto y recompensa para creadores
              </Text>
              <Text mb={5}>
                Establece el presupuesto total y cuánto pagarás a los creadores.
                Cuanto mayores sean las recompensas, más atractivo será el reto
                para nuestros creadores.
              </Text>
              {/* Budget */}
              <FormControl mb={2}>
                <FormLabel>
                  <Flex alignItems="center">
                    <Text marginRight={1}>Presupuesto total</Text>
                    <Popover placement="right">
                      <PopoverTrigger>
                        <InfoOutlineIcon marginTop="2px" width="12px" />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader
                          fontFamily="DM Sans"
                          fontSize="12px"
                          fontWeight="bold"
                        >
                          Presupuesto
                        </PopoverHeader>
                        <PopoverBody>
                          <Text fontFamily="DM Sans" fontSize="12px">
                            Este será el presupuesto máximo que se distribuirá
                            según las recompensas que indiques más abajo para
                            pagar a los creadores.
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </FormLabel>
                <NumberInput
                  value={formData.budget === 0 ? '' : formData.budget}
                >
                  <NumberInputField
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                    placeholder="1000€"
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              {/* Budgets recomendados */}
              <Flex mb={3}>
                <Box
                  height="40px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr={2}
                  px={4}
                  py={2}
                  fontWeight="500"
                  borderRadius="6px"
                  fontSize="12px"
                  border="1px solid #e2e8f0"
                  bg={Number(formData.budget) === 500 ? '#e2e8f0' : 'white'}
                  color={Number(formData.budget) === 500 ? 'black' : '#718096'}
                  _hover={{
                    bg: '#e2e8f0',
                    color: 'black',
                  }}
                  cursor="pointer"
                  onClick={() =>
                    setFormData(prevData => ({ ...prevData, budget: 500 }))
                  }
                >
                  500€
                </Box>
                <Box
                  height="40px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr={2}
                  px={4}
                  py={2}
                  bg={Number(formData.budget) === 1000 ? '#e2e8f0' : 'white'}
                  color={Number(formData.budget) === 1000 ? 'black' : '#718096'}
                  fontWeight="500"
                  borderRadius="6px"
                  fontSize="12px"
                  border="1px solid #e2e8f0"
                  _hover={{
                    bg: '#e2e8f0',
                    color: 'black',
                  }}
                  cursor="pointer"
                  onClick={() =>
                    setFormData(prevData => ({ ...prevData, budget: 1000 }))
                  }
                >
                  1000€
                </Box>

                <Box
                  height="40px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mr={2}
                  px={4}
                  py={2}
                  bg={Number(formData.budget) === 2000 ? '#e2e8f0' : 'white'}
                  color={Number(formData.budget) === 2000 ? 'black' : '#718096'}
                  fontWeight="500"
                  borderRadius="6px"
                  fontSize="12px"
                  border="1px solid #e2e8f0"
                  _hover={{
                    bg: '#e2e8f0',
                    color: 'black',
                  }}
                  cursor="pointer"
                  onClick={() =>
                    setFormData(prevData => ({ ...prevData, budget: 2000 }))
                  }
                >
                  2000€
                </Box>
                <Box
                  height="40px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={4}
                  py={2}
                  bg={Number(formData.budget) === 5000 ? '#e2e8f0' : 'white'}
                  color={Number(formData.budget) === 5000 ? 'black' : '#718096'}
                  fontWeight="500"
                  borderRadius="6px"
                  fontSize="12px"
                  border="1px solid #e2e8f0"
                  _hover={{
                    bg: '#e2e8f0',
                    color: 'black',
                  }}
                  cursor="pointer"
                  onClick={() =>
                    setFormData(prevData => ({ ...prevData, budget: 5000 }))
                  }
                >
                  5000€
                </Box>
              </Flex>
              {/* Rewards */}
              <Flex>
                <FormControl mb={5} mr={3}>
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Pago fijo por vídeo</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Pago por vídeo o recompensa fija para los creadores
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Esta es la recompensa fija que ganará el creador
                              por la aprobación de su vídeo.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <NumberInput
                    value={formatToShowEur(fixedReward)}
                    onChange={handleChangeFixedReward}
                    defaultValue={4}
                    min={selectedPlatform === 'instagram' ? 8 : 5}
                    max={100}
                  >
                    <NumberInputField inputMode="numeric" placeholder="5€" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl mb={5}>
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Pago por 1.000 views</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Pago por 1.000 views o recompensa variables para
                            creadores
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Esta es la recompensa variable que ganarán los
                              creadores por cada 1.000 views que consigan
                              durante las primeras 48h desde que suban el vídeo.
                              Las views conseguidas después de esas primeras 48h
                              no son monetizadas por el creador.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <NumberInput
                    value={formatToShowEur(variableReward)}
                    onChange={handleChangeVariableReward}
                    defaultValue={1}
                    min={1}
                    max={20}
                  >
                    <NumberInputField inputMode="numeric" placeholder="1" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              {/* Botón siguiente */}
              <Box mt="50px" textAlign="right">
                <Button
                  bg="black"
                  color="white"
                  _hover={{ bg: '#000', opacity: 0.75 }}
                  size="lg"
                  onClick={() => goToChallengeDetails()}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Ir a detalles del reto
                </Button>
              </Box>
            </Box>

            {/* Resultados */}
            <Box w="40%">
              <Text fontSize="16px" fontWeight="bold" mb={2}>
                Previsualiza los resultados
              </Text>
              <Text mb={5}>
                Los resultados pueden cambiar en función de cómo establezcas tu
                presupuesto y cómo lo distribuyas entre las recompensas.
              </Text>
              {/* Resultados */}
              <Flex flexDir="column" bg="#e2e8f0" p={5} borderRadius="6px">
                <Box
                  bg="white"
                  p={3}
                  textAlign="center"
                  borderRadius="6px"
                  border="1px solid #e2e8f0"
                  mb={5}
                >
                  <Text>Views estimadas (mín)</Text>
                  <Text fontWeight="bold">{formattedViews}</Text>
                </Box>
                <Box
                  bg="white"
                  p={3}
                  textAlign="center"
                  borderRadius="6px"
                  border="1px solid #e2e8f0"
                >
                  <Text>Número de creadores (máx)</Text>
                  <Text fontWeight="bold">{roundedNumberOfCreators}</Text>
                </Box>
              </Flex>
              <Text mt={1} fontSize="10px" opacity={0.75}>
                Estos resultados son estimaciones y pueden variar según el reto
              </Text>
            </Box>
          </Flex>
        </Box>
      )}

      {activeStep === 2 && (
        <Box p={5} mb={20}>
          <Box>
            <Flex
              flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
            >
              {/* Campos del reto */}
              <Box
                mb={5}
                mr={['0', '0', '50px', '80px']}
                width={['100%', '100%', '100%', '600px']}
              >
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Detalles del reto
                </Text>
                <Text fontSize="16px" mb={5}>
                  En los siguientes campos debes explicar a los creadores qué
                  quieres que hagan y qué requisitos mínimos deben incluir en
                  los vídeos. ¡Sé lo más conciso y breve que puedas!
                </Text>

                {/* Name */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Nombre del reto</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Nombre del reto
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Escribe un título para este reto. Será lo primero
                              que vean los creadores
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="E.g: Nueva McDalena de Chocolate Negro"
                  />
                </FormControl>

                {/* Description rich text */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Descripción</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Breve descripción o briefing
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Escribe de forma concisa lo que quieres que los
                              creadores hagan o muestren en este reto
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <TextEditor
                    placeholderContent="Ve a tu McDonalds más cercano y grábate probando la nueva McDalena de Chocolate. Asegúrate de mencionar lo jugosa que está y la cantidad de chocolate negro que lleva. ¡Anímate a hacer el reto con tus amigos!"
                    value={descriptionHTML}
                    onChange={setDescriptionHTML}
                  />
                </FormControl>

                {/* Requirements */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Requisitos mínimos (opcional)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Requisitos del vídeo
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px" mb={2}>
                              Elige qué cosas son imprescindibles para ti o qué
                              no quieres que aparezca
                            </Text>
                            <Text
                              textDecor="underline"
                              fontFamily="DM Sans"
                              fontSize="12px"
                            >
                              Ejemplos:
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Muestra tu cara</strong>
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>No uses voces robóticas</strong>
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Grábate en tu cuarto o similar</strong>
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputRequirementValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setRequirements(newValue)}
                    onInputChange={newValue =>
                      setInputRequirementValue(newValue)
                    }
                    onKeyDown={handleKeyDownRequirements}
                    placeholder="Escribe y pulsa enter. Ej: muestra tu cara"
                    value={requirements}
                  />
                </FormControl>

                {/* Links */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Enlaces (opcional)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Enlaces
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px" mb={2}>
                              Aquí puedes publicar algunos enlaces que
                              consideres importantes para la consecución del
                              reto. Enlaces a tu web, a alguna canción..
                            </Text>
                            <Text
                              textDecor="underline"
                              fontFamily="DM Sans"
                              fontSize="12px"
                            >
                              Por ejemplo:
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Texto del enlace:</strong> Web CRUWI
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Link URL:</strong> https://www.cruwi.com
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  {links.map((link, index) => (
                    <Flex flexDirection="row" mb={2} key={index} width="100%">
                      <Input
                        mr={3}
                        value={link.text}
                        placeholder={`Texto del enlace ${index + 1}`}
                        onChange={e =>
                          handleLinkChange(index, 'text', e.target.value)
                        }
                      />
                      <Input
                        value={link.url}
                        placeholder={`Link URL ${index + 1}`}
                        onChange={e =>
                          handleLinkChange(index, 'url', e.target.value)
                        }
                      />
                      {index > 0 && (
                        <IconButton
                          aria-label="Remove Link"
                          icon={<FiMinusCircle />}
                          ml={2}
                          onClick={() => removeLink(index)}
                        />
                      )}
                    </Flex>
                  ))}
                  <Button mt={1} onClick={addLink}>
                    Añadir otro
                  </Button>
                </FormControl>

                {/* Release date */}
                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Fecha de lanzamiento deseada</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Fecha de lanzamiento
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Los retos tendrán una duración de un mes en la
                              plataforma, es decir, estarán 1 mes publicados en
                              la plataforma públicamente para los creadores.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="launchDate"
                    value={formData.launchDate}
                    onChange={handleChange}
                    type="date"
                    placeholder="End Date"
                  />
                </FormControl>

                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Fecha de finalización</Text>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="finalizationDate"
                    value={formData.finalizationDate}
                    onChange={handleChange}
                    type="date"
                    placeholder="End Date"
                  />
                </FormControl>

                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Hashtags y menciones
                </Text>

                <Text fontSize="16px" mb={5}>
                  Escribe los hashtags y menciones que quieres que pongan los
                  creadores en la descripción de sus vídeos. Aprovecha para
                  posicionar los hashtags que más te convengan en tu estrategia.
                </Text>

                {/* Hashtags */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Hashtags</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Hashtags
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Elige qué hashtags quieres poner como obligatorios
                              en el vídeo que los creadores subirán a TikTok.
                              Los que quieras posicionar. Recomendamos poner 3 o
                              4.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputHashtagValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setHashtags(newValue)}
                    onInputChange={newValue => setInputHashtagValue(newValue)}
                    onKeyDown={handleKeyDownHashtags}
                    placeholder="Escribe y pulsa enter"
                    value={hashtags}
                  />
                </FormControl>

                {/* Mentions */}
                <FormControl mb="50px">
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Menciones</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Mentions
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Elige qué menciones quieres poner como
                              obligatorias en el vídeo que los creadores subirán
                              a TikTok. Normalmente pondrás la mención a tu
                              marca.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputMentionValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setMentions(newValue)}
                    onInputChange={newValue => setInputMentionValue(newValue)}
                    onKeyDown={handleKeyDownMentions}
                    placeholder="Escribe y pulsa enter"
                    value={mentions}
                  />
                </FormControl>

                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Material de apoyo (recomendado)
                </Text>
                <Text mb={5}>
                  Puedes subir un banner para la cabecera de tu reto y un vídeo
                  de ejemplo que sirva de inspiración a los creadores. Esto
                  añadirá contexto al reto y facilitará que los creadores
                  participen.
                </Text>

                {/* Banner */}
                <FormControl mb={8}>
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Imagen de cabecera (400x150)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Imagen cabecera reto
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Puedes utilizar tu propia imagen para el reto.
                              Puede ser una imagen específica del reto o una
                              imagen imagen más genérica de su marca. Si no
                              añades ninguna, se pondrá la que ves por defecto.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Box
                    borderWidth={2}
                    borderColor="rgb(226, 232, 240)"
                    borderStyle="dashed"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    position="relative"
                    maxHeight="200px"
                  >
                    {selectedBanner ? (
                      <Box
                        width="400px"
                        height="150px"
                        backgroundPosition="center"
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundImage={selectedBanner}
                      />
                    ) : (
                      <Text>Arrastra y suelta el archivo aquí, o</Text>
                    )}
                    <Input
                      type="file"
                      onChange={handleImageBanner}
                      display="none"
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="blue.500"
                        cursor="pointer"
                        textDecoration="underline"
                      >
                        haz clic para subir una imagen
                      </Text>
                    </label>
                  </Box>
                </FormControl>

                {/* Video example */}
                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Vídeo de ejemplo</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Vídeo de ejemplo
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Sube un vídeo que pueda servir de ejemplo o
                              inspiración para realizar el reto.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Box
                    borderWidth={2}
                    borderColor="rgb(226, 232, 240)"
                    borderStyle="dashed"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    position="relative"
                    maxHeight="200px"
                  >
                    {selectedVideo ? (
                      <>
                        <Button
                          onClick={() => setIsModalOpen(true)}
                          colorScheme="teal"
                          mt={4}
                          size="sm"
                        >
                          Abrir vídeo de ejemplo
                        </Button>
                        <br />
                      </>
                    ) : (
                      <Text>Arrastra y suelta el archivo aquí, o</Text>
                    )}
                    <Input
                      type="file"
                      display="none"
                      accept=".mp4, .webm, .ogg"
                      onChange={handleVideoUpload}
                      mt={4}
                      id="file-input-video"
                    />

                    <label htmlFor="file-input-video">
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="blue.500"
                        cursor="pointer"
                        textDecoration="underline"
                      >
                        haz clic para subir un vídeo
                      </Text>
                    </label>

                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      size="sm"
                      isCentered
                    >
                      <ModalOverlay />
                      <ModalContent color="black">
                        <ModalHeader fontSize="16px">Vídeo ejemplo</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody p={0}>
                          <video
                            controls
                            autoPlay
                            muted
                            style={{ width: '100%', height: '100%' }}
                          >
                            <source src={selectedVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            bg="black"
                            color="white"
                            _hover={{ bg: '#000', opacity: 0.75 }}
                            size="sm"
                            onClick={() => setIsModalOpen(false)}
                          >
                            Cerrar
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Box>
                </FormControl>

                {/* Botones */}
                <Box mt="50px" textAlign="right">
                  <Button
                    colorScheme="gray"
                    mr={4}
                    size="lg"
                    onClick={() => setActiveStep(0)}
                    leftIcon={<ArrowBackIcon />}
                  >
                    Volver al presupuesto
                  </Button>
                  <Button
                    bg="black"
                    color="white"
                    _hover={{ bg: '#000', opacity: 0.75 }}
                    size="lg"
                    onClick={() => goToPayment()}
                    rightIcon={<ArrowForwardIcon />}
                    minW="250px"
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>

              {/* Preview del reto */}
              <Box
                position="sticky"
                top="20px"
                alignSelf="flex-start"
                height="fit-content"
                width={['100%', '100%', '100%', '500px']}
              >
                <Text fontSize="16px" fontWeight="bold" mb={3}>
                  Así verán el reto los creadores
                </Text>
                <ChallengePreviewerV2
                  brand={brandData?.data}
                  challengeName={formData.name}
                  participants={estimatedCreators}
                  fixedReward={fixedReward}
                  description={descriptionHTML}
                  banner={selectedBanner}
                  exampleVideo={selectedVideo}
                  setIsModalOpen={setIsModalOpen}
                  hashtags={hashtags}
                  mentions={mentions}
                  requirements={requirements}
                  links={links}
                  launchDate={formData.launchDate}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      )}

      {activeStep === 3 && (
        <Box p={5} mb={10}>
          {/* Textos e imagen */}
          <Flex>
            <Box mr="50px" width="600px">
              <Text fontSize="20px" fontWeight="bold" mb={2}>
                Información sobre el pago
              </Text>
              <Text fontSize="16px" mb={3}>
                Estás a un paso de crear tu reto. Cuando pulses en <br /> 'Crear
                reto', lo revisaremos personalmente durante las próximas 24
                horas laborables.
              </Text>
              <Text fontSize="16px" mb={3}>
                Si todo está perfecto,{' '}
                <strong>
                  te enviaremos un email con la información para realizar el
                  pago del presupuesto
                </strong>{' '}
                que indicaste.
              </Text>
              <Text fontSize="16px" mb={3}>
                Una vez realizado el pago; publicamos y comunicamos el reto a
                los más de 10.000 creadores registrados en CRUWI.
              </Text>
              <Text fontSize="16px">
                ¡Lo siguiente ya será aprobar o denegar los vídeos que van
                subiendo los creadores!
              </Text>

              <Box mt={5}>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Notificaciones del reto
                </Text>
                <Text fontSize="16px" mb={3}>
                  Establece en qué emails quieres recibir los avisos sobre este
                  reto. Recibirás un email cuando se lance el reto y un email
                  diario si tienes vídeos nuevos.
                </Text>

                {/* Hashtags */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Emails</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Hashtags
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Elige qué hashtags quieres poner como obligatorios
                              en el vídeo que los creadores subirán a TikTok.
                              Los que quieras posicionar. Recomendamos poner 3 o
                              4.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputEmailValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setNotificationEmails(newValue)}
                    onInputChange={newValue => setInputEmailValue(newValue)}
                    onKeyDown={handleKeyDownNotificationEmails}
                    placeholder="Escribe y pulsa enter"
                    value={notificationEmails}
                  />
                </FormControl>
              </Box>

              {/* Botón */}
              <Box mt="80px" textAlign="right" width="100%">
                <Button
                  colorScheme="gray"
                  mr={4}
                  size="lg"
                  onClick={() => setActiveStep(1)}
                  leftIcon={<ArrowBackIcon />}
                >
                  Volver a los detalles
                </Button>
                <Button
                  bg="black"
                  color="white"
                  _hover={{ bg: '#000', opacity: 0.75 }}
                  size="lg"
                  onClick={() => handleSubmit()}
                  isLoading={isLoadingCreateChallenge}
                  minW="250px"
                >
                  Crear reto
                </Button>
              </Box>
            </Box>

            <Box
              maxW="300px"
              backgroundPosition="top"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundImage={ChallengeCreationImage}
              height="430px"
              w="100%"
            ></Box>
          </Flex>
        </Box>
      )}

      {activeStep === 4 && <Box p={5} mb={10}></Box>}

      {/* Modal objetivo del reto */}
      <Modal isOpen={isOpenGoalModal} isCentered onClose={onCloseGoalModal}>
        <ModalOverlay backdropFilter="blur(5px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalHeader borderTopRadius="8px" bg="#e2e8f0">
            ¡Reto creado con éxito! ✅
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading mt={5} as="h4" size="md" mb={1}>
              ¿Cuál es tu objetivo con este reto?
            </Heading>
            <Text mb={5} fontSize="16px" lineHeight={1.3}>
              Tu respuesta nos ayudará a entender tus objetivos y a poder
              ayudarte mejor.
            </Text>
            <Textarea
              value={challengeGoal}
              onChange={handleChangeChallengeGoal}
              placeholder=""
            />
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              variant="ghost"
              onClick={handleCloseChallengeGoalModal}
            >
              Cerrar
            </Button>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: '#000', opacity: 0.75 }}
              onClick={handleSubmitChallengeGoal}
              isLoading={isLoadingUpdateChallenge}
            >
              Enviar respuesta
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
